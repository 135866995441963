<template>
  <div>
    <h3 ref="ia">
      What is a Hydrostatic Pressure?
    </h3>
    <p>
      Consider an object that is submerged in a fluid. The object experiences pressure due to weight of the fluid above it.
      Pressure experienced by an object submerged in a fluid at rest is called hydrostatic pressure.
    </p>
    <h3 ref="ja">
      Magnitude of Hydrostatic Pressure
    </h3>
    <p>
      The magnitude of hydrostatic pressure experienced by an object submerged in a fluid depends upon
      the density of the fluid and the depth.
      <br>
      $$P = \rho g h$$
      where \(\rho\) is the density of the fluid, \(g\) is the acceleration due to gravity and \(h\) is the depth.
      <br>
    </p>

    <h3 ref="pg">
      MagicGraph | Measuring the Depth of an Iceberg
    </h3>
    <p>Through this interactive module, students will learn how hydrostatic pressure at a point in a fluid changes with depth. </p>
    <p>
      Billy is an environmental scientist who studies icebergs. During one of his expeditions, he needs to measure the depth of an iceberg. <br>
      However, he doesn't have a measuring tape or a rope that is long enough to reach the bottom of the iceberg.
      So, he uses the hydrostatic pressure formula \(P =\rho g h\) to find the depth. <br>
      He knows the density of water is \(\rho = 1000 \text{ kg/m}^3\), and the acceleration due to gravity \(g = 9.8 \text{ m/s}^2\).<br>
      He figures out that if he can measure the pressure \(P\) at the depth
      of the iceberg, he can calculate the depth of the iceberg: \(h = P/\rho g \).<br>
    </p>
    <h5>To Get Started</h5>
    <p>
      <ul class="a">
        <li> Drag the diver to the depth of the iceberg. </li>
        <li> Take note of the pressure reading at that depth.</li>
        <li> Use the hydrostatic formula to calculate the depth. Enter the depth value in the blue box and tap on the test button to check your answer. </li>
        <li> Click the shuffle button to try more examples. </li>
      </ul>
    </p>

    <v-layout align-center
              justify-center
              row
              wrap
    >
      <v-responsive>
        <v-layout align-center justify-center>
          <div id="jxgbox1" class="edliy-box-about" />
        </v-layout>
      </v-responsive>
    </v-layout>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'HydrostaticPressure',
  metaInfo: {
          title: 'Hydrostatic Pressure',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {vmid:'description',name: 'description', content: 'Learn interactively about drawing a perpendicular.'},
                  {vmid:'keywords',name: 'keywords', content: 'Hydrostatic Pressure'}
                ]
   },
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Hydrostatic Pressure';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Free Body Diagram?', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Purpose of FBD', img:'/assets/number-2.svg', action: () => this.goto('ja')},
      {title: 'Example of FBD', img:'/assets/number-3.svg', action: () => this.goto('ka')},
      {title: 'MagicGraph' ,img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  }
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
