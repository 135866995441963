// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeDash
} from '../../../common/edliy_utils-circle';
const Boxes = {
    box1: function () {
    //Create the Board
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-9, 9,9, -9],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
   //suspend updates
    //brd1.suspendUpdate();
    // Layering
    brd1.options.layer['image']=15;
    brd1.options.layer['text']=16;
    brd1.options.layer['axis']=18;
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    brd1.options.line.highlight=false;
    brd1.options.image.highlight=false;
    //Make the board responsive
    makeResponsive(brd1);
    // Creat titleStyle
    placeTitle(brd1, 'Hydrostatic Pressure', '(Drag the diver down to measure the pressure at the bottom of the iceberg.)');
    //dragMe(brd1, 5, 5);
    var pts1 = [-15,0];
    var pts2 = [15,0];
    var pts3 = [15, -5];
    var pts4 = [-15, -5];
    var pts6= [-4, 6];
    var pts7 =[-4, 4];
    var j=0;
    var i =[[-7, 9.6], [-6, 8.1], [-8, 11],[-5, 6.85]];
    var k=[-8.15, -6.85, -9.35, -5.75];
    const options={highlight:false};
    //placeArrow(brd1, pts6, pts7, 'red');
    placeGravity(brd1, [-8,0],[-8, -2], 'g');
    //placeRec(brd1, [-10, -20], [10, -20], [10, 0], [-10, 0], 'lightblue');
    brd1.create('polygon', [[-10, -20], [10, -20], [10, 0], [-10, 0]],{fillOpacity:0.5, color:'lightblue', borders:{visible:false}, highlight:false, vertices:{visible:false}});
    //var soil =
    //placeImage(brd1,'/assets/iceberg.svg', -8.5, -7., 9.6, 0);
    placeImage(brd1,'/assets/iceberg.svg', ()=>k[j.valueOf()], ()=>i[j.valueOf()][0], ()=>i[j.valueOf()][1], 0);
    //placePoint(brd1, 1, 1, 4, 'white', 'black');
    var pt = placePoint(brd1,0, ()=>i[j.valueOf()][0], 0, 'white', 'black');
    var seg = placeLine(brd1, [0,0], [0,-10], 1, 'grey');
    var loc =placeGlider(brd1, seg, 0, -1.75, 0, 'white', 'black');
    var scuba=brd1.create('image', ['/assets/scuba.svg', [0, -2.25], [3, 3]], {highlight:false});
    brd1.on('move', function(){scuba.moveTo([0., scuba.Y()])});
    var baro=brd1.create('image', ['/assets/barometer.svg', [-0.275, ()=>scuba.Y()], [1, 1]], {highlight:false});
    placeImage(brd1,'/assets/puffy.svg', 3, -3., 1, 0, {...options});
    placeImage(brd1,'/assets/fish.svg', 2, -9., 1, 0);
    placeImage(brd1,'/assets/fish-2.svg', 7, -7., 1, 0);
    placeImage(brd1,'/assets/fish.svg', -7, -5., 1, 0);
    var shuffle =placeImage(brd1,'/assets/shuffle.svg', -7.625, 4.375, 1.25, 0);
    var horz =placeDash(brd1,[()=>scuba.X()-60, ()=>scuba.Y()+0.5], [()=>scuba.X()+60, ()=>scuba.Y()+0.5], 1, 'grey');
    horz.setAttribute({visible:false});
    placeRuler(brd1, [6, 0],[6, function(){return scuba.Y()+0.5;}], '5m');
    placeLeftText(brd1, 6.25, function(){return 0.5*scuba.Y()},  'd = ? m');
    scuba.on('down', function(){horz.setAttribute({visible:true})});
    scuba.on('up', function(){horz.setAttribute({visible:false})});
    hoverMe(brd1, shuffle,'Tap to Shuffle!', 10,  -20);
    shuffle.on('down', function(){
      if(j<3){j+=1;}else{j=0};
      right.setAttribute({visible:false});
      wrong.setAttribute({visible:false});
      let magicGraph = brd1.containerObj;
      let inputFields = magicGraph.querySelectorAll("input");
          for (let inputField of inputFields) {
                inputField.value = "";}});
    placeText(brd1, 6, function(){return scuba.Y();}, 20, function(){return 'P =' + (1000*9.8*(-scuba.Y()-0.5)).toFixed(2) + ' Pa'});
    var ans =placeInput(brd1, -4, 5, 0);
    placeText(brd1, -4.5, 5, 24, 'd = ');
    var checkans = placeImage(brd1,'/assets/test.svg', -2, 4.5, 1, 0);
    hoverMe(brd1, checkans, 'Tap to test your answer', 0, -20);
    var right = placeImage(brd1,'/assets/check.svg', 4, 4.5, 1, 0);
    right.setAttribute({visible:false});
    var wrong = placeImage(brd1,'/assets/cross.svg', 4, 4.5, 1, 0);
    wrong.setAttribute({visible:false});
    var check = function(){
      if(ans.Value() <= -i[j.valueOf()][0]+0.05 && ans.Value() >= -i[j.valueOf()][0]-0.05)
      {right.setAttribute({visible:true});wrong.setAttribute({visible:false});}
      else
      {wrong.setAttribute({visible:true});right.setAttribute({visible:false});}
    }
    checkans.on('down', function(){check();});
    hoverMe(brd1, scuba, 'Drag Me!', 0, -10);
      },
              }
export default Boxes;
